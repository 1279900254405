.loader {
  border: 5px solid #c7d504;
  border-top: 5px solid #364b21;
  border-radius: 50%;
  /* width: 80px;
  height: 80px; */
  animation: spin 1s linear infinite;
  /* margin-left: 45%;
  margin-top: 50px; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}