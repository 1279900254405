
.bg-apple-green {
  background-color: $apple-green !important;
}

.bg-bitter-lemon {
  background-color: $bitter-lemon !important;
}

.bg-android-green {
  background-color: $android-green !important;
}

.bg-palm-leaf {
  background-color: $palm-leaf !important;
}

.bg-dark-moss-green {
  background-color: $dark-moss-green !important;
}

.bg-kombu-green {
  background-color: $kombu-green !important;
}
